import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import foodStyles from "./food.module.css"

import headerImage from "../images/Taproom_header.jpg"

const FoodPage = ({ data }) => {
  const foodTrucks = data.foodTrucks.nodes;
  const foodTruckDates = data.foodTruckDates.nodes;

  foodTruckDates.forEach(e => {
    e.frontmatter.foodTruckDate = new Date(e.frontmatter.foodTruckDate);
  });

  foodTruckDates.sort((a, b) => a.frontmatter.foodTruckDate - b.frontmatter.foodTruckDate)

  const todayDate = new Date();

  const foundDate = foodTruckDates.find(e => e.frontmatter.foodTruckDate >= todayDate || isSameDay(e.frontmatter.foodTruckDate, todayDate));
  const foundDateIndex = foodTruckDates.indexOf(foundDate);
  const truckDates = foodTruckDates.slice(foundDateIndex, foundDateIndex + 28);

  // State for the list
  const [list, setList] = useState([...truckDates.slice(0, 7)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(truckDates.length > 7)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < truckDates.length
      const nextResults = isMore
        ? truckDates.slice(currentLength, currentLength + 7)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < truckDates.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <Layout>
      <SEO title="Food" />
      <img src={headerImage} className={foodStyles.backgroundImage} alt="Hanging lights." />
      <div className={foodStyles.backgroundColor}></div>
      <h1 className={foodStyles.title}>FOOD OPTIONS<br />AT STODGY</h1>
      {/* <a href="https://stodgybrewing.square.site/"><div className={foodStyles.orderButton}>ORDER BEER PICKUP</div></a> */}
      {/* <p className={foodStyles.subtitle}>IN NORTH-WEST FORT COLLINS</p> */}
      <div className={foodStyles.foodTruckSection}>
        <span className={foodStyles.foodWord}>FOOD</span>
        <div className={foodStyles.foodTruckDates}>
          <ol>
            {list.map(date => {
              const truck = foodTrucks.find(truck => { return truck.frontmatter.title === date.frontmatter.foodTruck });
              const truckDate = date.frontmatter.foodTruckDate;

              return (
                <li>
                  <p className={foodStyles.foodTruckDate}>{truckDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', weekday: 'long' })}</p>
                  <a className={foodStyles.foodTruckName} href={truck.frontmatter.link} alt="Link to food truck." target="_blank" rel="noreferrer noopener">{date.frontmatter.foodTruck}</a>
                  <p className={foodStyles.foodTruckTime}>{date.frontmatter.startTime} - {date.frontmatter.endTime}</p>
                </li>
              )
            })}
          </ol>
        </div>
        {hasMore ? (
          <button className={foodStyles.orderButton} onClick={handleLoadMore}>Load More</button>
        ) : (
          <div></div>
        )}
      </div>
      <Img fluid={data.beerCollectionImage.childImageSharp.fluid} alt="" className={foodStyles.beerCollectionImage} />
    </Layout>
  )
}

const isSameDay = (a, b) => {
  return a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
}

export default FoodPage

export const query = graphql`
  query {
    beerCollectionImage: file(relativePath: { eq: "beer_collection.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    foodTruckDates: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "food-truck-date"}}}, sort: {fields: frontmatter___foodTruckDate, order: ASC}) {
        nodes {
            frontmatter {
                foodTruckDate
                foodTruck
                startTime
                endTime
            }
        }
    }
    foodTrucks: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "food-truck"}}}) {
        nodes {
          frontmatter {
            title
            link
          }
        }
    }
  }
`
